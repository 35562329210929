import { create } from 'zustand';
import { sessionEndpoint } from '../constants/endpoints';
import axiosInstance from '../utils/axiosInstance';

const nullUser = {
  email: null,
  username: null,
  status: null,
  account_status: null,
};

export const accountStatusEnum = {
  NEW_ACCOUNT: '0000',
  SURVEY_COMPLETE: '9a5b',
  IB_APPLICATION_COMPLETE: '5b1a',
  ACCOUNT_OPEN: '8a8a',
};

const useUserStore = create((set, get) => ({
  user: nullUser,
  setUser: ({ email, username, status, account_status }) =>
    set(() => ({
      user: {
        email,
        username,
        status,
        account_status,
      },
    })),
  fetchUserSession: async () => {
    try {
      const response = await axiosInstance.get(sessionEndpoint);
      const data = response.data;
      const { email, username, account_status } = data.user;
      set({
        user: { email, username, status: data?.status, account_status },
      });
    } catch (e) {
      set({ user: nullUser });
    }
  },
  isUserLoggedIn: () => {
    const { user } = get();
    return user.email && user.username;
  },
  hasUserCompletedSurvey: () => {
    const { user } = get();
    return user.account_status === accountStatusEnum.SURVEY_COMPLETE;
  },
  hasUserCompletedApplication: () => {
    const { user } = get();
    return user.account_status === accountStatusEnum.IB_APPLICATION_COMPLETE;
  },
  hasUserOpenAccount: () => {
    const { user } = get();
    return user.account_status === accountStatusEnum.ACCOUNT_OPEN;
  },
  getAccountStatus: () => {
    const { user } = get();
    return user.account_status;
  },
  logout: () => set({ user: nullUser }),
  updateUserAccountStatus: (newAccountStatus) =>
    set((state) => ({ user: { ...state.user, account_status: newAccountStatus } })),
}));

export default useUserStore;
