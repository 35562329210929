import React from 'react';

import Calendar from './Calendar';
import Close from './Close';
import Logo from './Logo';
import Mail from './Mail';
import Arrow from './Arrow';
import Print from './Print';
import SimpleArrow from './SimpleArrow';
import Warning from './Warning';
import ImageUpload from './ImageUpload';
import Draw from './Draw';
import Keyboard from './Keyboard';
import Doc from './Doc';

const Icon = ({ icon = '', ...rest }) => {
  const icons = {
    logo: <Logo {...rest} />,
    mail: <Mail {...rest} />,
    close: <Close {...rest} />,
    arrow: <Arrow {...rest} />,
    print: <Print {...rest} />,
    simpleArrow: <SimpleArrow {...rest} />,
    warning: <Warning {...rest} />,
    keyboard: <Keyboard {...rest} />,
    'image-upload': <ImageUpload {...rest} />,
    draw: <Draw {...rest} />,
    doc: <Doc {...rest} />,
  };

  return icons[icon] || null;
};

export { Calendar, Close, Icon, Logo, Mail };
