import axiosInstance from '../axiosInstance';
import { logoutEndpoint } from '../../constants/endpoints';
export default async function logout() {
  try {
    const response = await axiosInstance.get(logoutEndpoint);
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
}
