import React from 'react';

const Arrow = ({ className }) => {
  return (
    <svg className={className} viewBox='0 0 39 39' fill='none'>
      <path
        d='M14.3002 8.5791L25.0089 18.9633L14.6246 29.672'
        stroke='#6F6F6F'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Arrow;
