import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Calendar = ({ className }) => {
  return (
    <svg
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames('icon', 'icon-calendar', { [className]: className })}
    >
      <path d="M0.666992 4.41634C0.666992 3.93011 0.860146 3.4638 1.20396 3.11998C1.54778 2.77616 2.0141 2.58301 2.50033 2.58301H13.5003C13.9866 2.58301 14.4529 2.77616 14.7967 3.11998C15.1405 3.4638 15.3337 3.93011 15.3337 4.41634V15.4163C15.3337 15.9026 15.1405 16.3689 14.7967 16.7127C14.4529 17.0565 13.9866 17.2497 13.5003 17.2497H2.50033C2.0141 17.2497 1.54778 17.0565 1.20396 16.7127C0.860146 16.3689 0.666992 15.9026 0.666992 15.4163V4.41634Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.667 0.75V4.41667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.33301 0.75V4.41667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.666992 8.08301H15.3337" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.33301 11.75H6.16634V13.5833H4.33301V11.75Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

Calendar.propTypes = {
  className: PropTypes.string,
};

export default Calendar;
