import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Close = ({ className }) => {
  return (
    <svg
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classnames('icon', 'icon-close', { [className]: className })}
    >
      <rect width='25' height='25' fill='none' />
      <g id='CLIENT LOGIN'>
        <g id='Rectangle 13' filter='url(#filter0_di_0_1)'>
          <rect x='-603' y='-29' width='665' height='523' rx='10' fill='none' />
          <rect x='-602.5' y='-28.5' width='664' height='522' rx='9.5' />
        </g>
        <path
          id='Vector'
          d='M22.8548 2.21273L2.42942 23.0715M2.21273 2.42942L23.0715 22.8548'
          stroke='currentColor'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_di_0_1'
          x='-615'
          y='-41'
          width='701'
          height='559'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='6' dy='6' />
          <feGaussianBlur stdDeviation='9' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.979167 0 0 0 0 0.971007 0 0 0 0 0.971007 0 0 0 0.25 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_0_1' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_0_1' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='10.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='shape' result='effect2_innerShadow_0_1' />
        </filter>
        <linearGradient
          id='paint0_linear_0_1'
          x1='-78.6168'
          y1='-195.187'
          x2='-139.633'
          y2='521.094'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CAC6C6' />
          <stop offset='1' stopColor='#CAC6C6' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};

Close.propTypes = {
  className: PropTypes.string,
};

export default Close;
