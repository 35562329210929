import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import './FormCRS.css';

const FormCRS = () => {
  return (
    <Layout hideContact title={'Form CRS'}>
      <div className='h-wrapper'>
        <div className='form-crs'>
          <h3 className='title'>Richmond Capital Advisors LLC Relationship Summary (Form CRS)</h3>
          <p>Form Effective Date: October 1, 2024</p>

          <h4>Introduction</h4>
          <p>
            Richmond Capital Advisors LLC is an investment advisory firm registered with the
            Securities and Exchange Commission (SEC). It is important for you to understand how
            advisory and brokerage services and fees differ to determine which type of account is
            right for you. There are free and simple tools available to research firms and financial
            professionals at <Link to='http://www.investor.gov/crs'>www.investor.gov/crs</Link>{' '}
            which also provides educational materials about investment advisers, broker-dealers and
            investing.
          </p>

          <h4>What types of investment services and advice can you provide me?</h4>
          <p>
            Our firm primarily offers the following investment advisory services to retail clients:
            “robo” portfolio management (we review your portfolio, investment strategy, and
            investments) through an online interface. Robo-advisory portfolio management accounts
            are not reviewed by the firm, save for automated allocation revisions. We typically do
            not monitor client accounts on an ongoing basis. Our firm offers discretionary advisory
            services (where our firm makes the decision regarding the purchase or sale of
            investments). We limit the types of investments that are recommended since not every
            type of investment vehicle is needed to create an appropriate portfolio, but do not
            limit these investments to proprietary products. Our firm does have a minimum account
            size of $100,000. Additional information about our advisory services is discussed in
            Items 4 and 7 of our Firm Brochure which is available at:{' '}
            <Link to='http://adviserinfo.sec.gov/firm/summary/327060'>
              adviserinfo.sec.gov/firm/summary/327060
            </Link>
          </p>

          <h4>Questions to Ask Us:</h4>
          <ul>
            <li>
              Given my financial situation, should I choose an investment advisory service? Why or
              why not?
            </li>
            <li>How will you choose investments to recommend to me?</li>
            <li>
              What is your relevant experience, including your licenses, education and other
              qualifications?
            </li>
            <li>What do those qualifications mean?</li>
          </ul>

          <h4>What fees and costs will I pay?</h4>
          <p>
            Our clients pay a fee based on assets under management. Additionally, the amount of
            assets in your account affects our advisory fee; the more assets you have in your
            advisory account, the more you will pay us and thus we have an incentive to increase
            those assets in order to increase our fee. Robo-advisory portfolio management fees are
            withdrawn directly from the client&apos;s accounts with client&apos;s written
            authorization on a monthly basis. Fees are paid in arrears. You pay our fees even if you
            do not have any transactions and the advisory fee paid to us generally does not vary
            based on the type of investments selected.
          </p>
          <p>
            Some investments (e.g., mutual funds, variable annuities, etc.) impose additional fees
            (e.g., transactional fees and product-level fees) that reduce the value of your
            investment over time. The same goes for any additional fees you pay to a custodian.
            Additionally, you will pay transaction fees, if applicable, when we buy or sell an
            investment for your account.
          </p>
          <p>
            You will pay fees and costs whether you make or lose money on your investments. Fees and
            costs will reduce any amount of money you make on your investments over time. Please
            make sure you understand what fees and costs you are paying.
          </p>
          <p>
            Additional information about fees and other costs is discussed in Item 5 of our Firm
            Brochure. Our Firm Brochure is available at:{' '}
            <Link to='http://adviserinfo.sec.gov/firm/summary/327060'>
              adviserinfo.sec.gov/firm/summary/327060
            </Link>
          </p>

          <h4>Questions to Ask Us:</h4>
          <ul>
            <li>Help me understand how these fees and costs will affect my investments.</li>
            <li>
              If I give you $10,000 to invest, how much will go to fees and costs and how much will
              be invested for me?
            </li>
            <li>How do you determine what fee I will be charged?</li>
          </ul>

          <h4>
            What are your legal obligations to me when acting as my investment adviser? How does
            your firm make money and what conflicts of interest do you have?
          </h4>
          <p>
            When we act as your investment adviser, we have to act in your best interest and not put
            our interest ahead of yours. At the same time, the way we make money creates some
            conflicts with your interests. You should understand and ask us about these conflicts
            because they can affect the investment advice we provide you. Here are some examples to
            help you understand what this means: For AUM fees, the more assets you have in your
            advisory account, the more you will pay us and thus we have an incentive to increase
            those assets in order to increase our fee.
          </p>
          <p>
            Additional information about our conflicts of interest is discussed under Items 10, 11,
            and 12 in our Firm Brochure which is available at:{' '}
            <Link to='http://adviserinfo.sec.gov/firm/summary/327060'>
              adviserinfo.sec.gov/firm/summary/327060
            </Link>
          </p>

          <h4>Questions to Ask Us:</h4>
          <ul>
            <li>How might your conflicts of interest affect me, and how will you address them?</li>
          </ul>

          <h4>How do your financial professionals make money?</h4>
          <p>
            Primarily, we and our financial professionals receive cash compensation from the
            advisory services we provide to you because of the advisory fees we receive from you.
            This compensation may vary based on different factors, such as those listed above in
            this Item. Our financial professionals also have the ability to receive commissions from
            clients and therefore have an incentive to recommend products that provide them or us
            additional compensation over those that do not.
          </p>

          <h4>Do you or your financial professionals have legal or disciplinary history?</h4>
          <p>No.</p>
          <p>
            Visit <Link to='http://www.investor.gov/crs'>www.investor.gov/crs</Link> for a free and
            simple search tool to research our firm and our financial professionals.
          </p>

          <h4>Questions to Ask Us:</h4>
          <ul>
            <li>
              As a financial professional, do you have any disciplinary history? For what type of
              conduct?
            </li>
          </ul>

          <h4>Additional Information</h4>
          <p>
            You can find additional information about our firm&apos;s investment advisory services
            on the SEC&apos;s website at{' '}
            <Link to='http://www.adviserinfo.sec.gov'>www.adviserinfo.sec.gov</Link> by searching
            CRD #327060. You may also contact our firm at 305-510-8833 and talk to any one of our
            investment professionals for up-to-date information or to request a copy of the
            relationship summary.
          </p>

          <h4>Questions to Ask Us:</h4>
          <ul>
            <li>Who is my primary contact person?</li>
            <li>Is he or she a representative of an investment adviser or a broker-dealer?</li>
            <li>Who can I talk to if I have concerns about how this person is treating me?</li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default FormCRS;
