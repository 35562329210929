import React from 'react';
const Loading = ({ text, className }) => {
  return (
    <div className={`loading ${className || ''}`}>
      <div>
        {text?.length > 0 && <span>{text}</span>}
        <div className='spinner'></div>
      </div>
    </div>
  );
};

export default Loading;
